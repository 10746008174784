import * as tslib_1 from "tslib";
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { CHECKOUT_CHARGE_PAYMENT_TYPE } from "./checkout-charge-popup.config";
// declare var Square: any;
var CheckoutChargePopupComponent = /** @class */ (function () {
    function CheckoutChargePopupComponent(loaderService, languageService, cdr, currencyService, cashappPayService, popupComponent) {
        this.loaderService = loaderService;
        this.languageService = languageService;
        this.cdr = cdr;
        this.currencyService = currencyService;
        this.cashappPayService = cashappPayService;
        this.popupComponent = popupComponent;
        this.show = false;
        this.close = new EventEmitter();
        this.emitDepositConfirm = new EventEmitter();
        this.CHECKOUT_CHARGE_PAYMENT_TYPE = CHECKOUT_CHARGE_PAYMENT_TYPE;
    }
    CheckoutChargePopupComponent.prototype.ngAfterViewChecked = function () {
        this.cdr.detectChanges();
    };
    CheckoutChargePopupComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp) {
                            this.cashappPayService.paymentResult.subscribe(function (tokenResult) {
                                console.log(tokenResult);
                                if (tokenResult) {
                                    if (tokenResult.status === 'OK') {
                                        console.log("Payment token is " + tokenResult.token);
                                        var body = {
                                            token: tokenResult.token
                                        };
                                        // $.post("https://api.dev.eboxenterprises.com/test/square.php?tt=1", body, (data, status) => {
                                        //   console.log(data);
                                        // });
                                        _this.onDepositConfirm(tokenResult.token);
                                        // alert('request is here');
                                        // console.log(body)
                                    }
                                    else {
                                        console.error(tokenResult);
                                        var message = _this.languageService.getString('messagesData', 'paymentIsCanceled');
                                        _this.popupComponent.showPopupError({ text: message });
                                        _this.closePopup(false);
                                    }
                                    //document.body.classList.remove('hide-menu');
                                    _this.cashappPayService.paymentResult.next(null);
                                }
                            });
                        }
                        if (!(!this.cashappPayService.isCashAppPayScripExist() && (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashappPayService.createCashAppPayButton(this.popupData.cashappInfo, this.popupData.inputAmount)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.onShowCashApp()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CheckoutChargePopupComponent.prototype.closePopup = function (reload) {
        if (reload === void 0) { reload = false; }
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.cashappPayService.destroyCashApp();
    };
    CheckoutChargePopupComponent.prototype.ngOnChanges = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.popupData) return [3 /*break*/, 4];
                        if (!this.show) return [3 /*break*/, 4];
                        document.body.classList.add('hide-menu');
                        setTimeout(function () {
                            document.body.querySelectorAll('.snake-btn').forEach(function (el) {
                                el.classList.add('is-notouched');
                            });
                        });
                        if (!(this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp)) return [3 /*break*/, 4];
                        if (!!this.cashappPayService.isCashAppPayScripExist()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashappPayService.createCashAppPayButton(this.popupData.cashappInfo, this.popupData.inputAmount)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.onShowCashApp()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CheckoutChargePopupComponent.prototype.onDepositConfirm = function (token) {
        if (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp) {
            this.loaderService.show();
            this.emitDepositConfirm.emit({
                amount: this.popupData.inputAmount,
                token: token
            });
        }
    };
    CheckoutChargePopupComponent.prototype.onShowCashApp = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashappPayService.showCashAppPayButton(this.popupData.inputAmount)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CheckoutChargePopupComponent.prototype.formatCurrency = function (amount) {
        return this.currencyService.formatCurrency(amount);
    };
    return CheckoutChargePopupComponent;
}());
export { CheckoutChargePopupComponent };
