import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {CashappPayService} from "../../service/cashapp-pay.service";
import {LanguageService} from "../../modules/language/language.service";
import {CurrencyService} from "../../modules/currency/currency.service";
import {CHECKOUT_CHARGE_PAYMENT_TYPE} from "./checkout-charge-popup.config";
import {PopupComponent} from "../popup/popup.component";

// declare var Square: any;

@Component({
    selector: 'app-checkout-charge-popup',
    templateUrl: './checkout-charge-popup.component.html',
    styleUrls: ['./checkout-charge-popup.component.scss']
})
export class CheckoutChargePopupComponent implements OnInit {
    @Input() show = false;
    @Input() popupData;
    @Output() close = new EventEmitter();
    @Output() emitDepositConfirm = new EventEmitter();

    CHECKOUT_CHARGE_PAYMENT_TYPE = CHECKOUT_CHARGE_PAYMENT_TYPE;

    @ViewChild('imageInput', {static: false}) imageInput;
    @ViewChild('termsAccept', {static: false}) termsAccept: ElementRef;

    constructor(private loaderService: LoaderService,
                private languageService: LanguageService,
                private cdr: ChangeDetectorRef,
                private currencyService: CurrencyService,
                private cashappPayService: CashappPayService,
                private popupComponent: PopupComponent,) {

    }

    ngAfterViewChecked(){
        this.cdr.detectChanges();
    }

    async ngOnInit() {
        if (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp) {
            this.cashappPayService.paymentResult.subscribe(tokenResult => {
                console.log(tokenResult);
                if (tokenResult) {
                    if (tokenResult.status === 'OK') {
                        console.log(`Payment token is ${tokenResult.token}`);

                        const body = {
                            token: tokenResult.token
                        };
                        // $.post("https://api.dev.eboxenterprises.com/test/square.php?tt=1", body, (data, status) => {
                        //   console.log(data);
                        // });
                        this.onDepositConfirm(tokenResult.token);

                        // alert('request is here');
                        // console.log(body)
                    } else {
                        console.error(tokenResult);
                        const message = this.languageService.getString('messagesData', 'paymentIsCanceled');
                        this.popupComponent.showPopupError({text: message});
                        this.closePopup(false);

                    }
                    //document.body.classList.remove('hide-menu');
                    this.cashappPayService.paymentResult.next(null);
                }
            })
        }
        if (!this.cashappPayService.isCashAppPayScripExist() && (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp)) {
            await this.cashappPayService.createCashAppPayButton(this.popupData.cashappInfo, this.popupData.inputAmount);
        } else {
            await this.onShowCashApp();
        }
    }

    closePopup(reload = false) {
        this.close.emit(reload);
        document.body.classList.remove('hide-menu');
        this.cashappPayService.destroyCashApp();
    }

    async ngOnChanges() {
        if (this.popupData) {
            if (this.show) {
                document.body.classList.add('hide-menu');

                setTimeout(() => {
                    document.body.querySelectorAll('.snake-btn').forEach(el => {
                        el.classList.add('is-notouched');
                    });
                });

                if (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp) {
                    if (!this.cashappPayService.isCashAppPayScripExist()) {
                        await this.cashappPayService.createCashAppPayButton(this.popupData.cashappInfo, this.popupData.inputAmount);
                    } else {
                        await this.onShowCashApp();
                    }
                }
            }
        }
    }

    onDepositConfirm(token?) {
        if (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp) {
            this.loaderService.show();
            this.emitDepositConfirm.emit({
                amount: this.popupData.inputAmount,
                token
            });
        }
    }

    async onShowCashApp() {
        if (this.popupData.payment === this.CHECKOUT_CHARGE_PAYMENT_TYPE.cashApp) {
            await this.cashappPayService.showCashAppPayButton(this.popupData.inputAmount);
        }
    }

    formatCurrency(amount) {
        return this.currencyService.formatCurrency(amount);
    }
}
